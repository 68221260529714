.splash {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    z-index: 100;

    > img {
        max-height: 50px;
    }
}