.footer {
    background-color: black;
    padding: 100px 50px;
    display: flex;
    flex-flow: column;
    align-items: center;
    color: white;
    margin-top: 50px;

    > img {
        max-height: 50px;
    }

    > h1 {
        font-size: 25px;
        text-align: center;
        margin: 60px 0;
        max-width: 900px;
    }

    > .socials {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        > a {
            color: white;
            text-decoration: none;
            margin: 5px 30px;
            font-size: 14px;
            white-space: nowrap;
            display: inline-block;

            &:hover {
                color: orange;
            }
        }

        > span {
            color: #999;
        }
    }
}

@media (max-width: 500px) {
    .footer > .socials {
        > span {
            display: none;
        }
    }
}
