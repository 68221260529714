.eid_order {
    display: flex;
    flex-flow: column;
    align-items: center;

    > img {
        width: 150px;
    }

    > h1 {
        font-size: 40px;
        font-weight: 700;
        line-height: 1;
        margin: 30px 0 30px;

        > small {
            font-size: 20px;
            font-family: inherit;
        }
    }

    > p {
        margin-top: 30px;
        padding: 0 30px;
        text-align: center;
    }
}