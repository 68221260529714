.book_a_session {
    padding: 100px 30px 70px;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    
    > h1 {
        font-size: 25px;
        font-weight: 700;
        max-width: 700px;
        margin-bottom: 30px;
    }
    > p {
        margin-bottom: 30px;
    }
}