@font-face {
    font-family: SourceSerifPro;
    src: url(./Source_Serif_Pro/SourceSerifPro-Italic.ttf);
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: SourceSerifPro;
    src: url(./Source_Serif_Pro/SourceSerifPro-BoldItalic.ttf);
    font-weight: 700;
    font-style: italic;
}