.learn_more {
    display: flex;
    flex-flow: row wrap;

    > section {
        flex: 1 1 400px;
        max-height: 650px;

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        > article {
            padding: 50px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: flex-start;
            height: 100%;

            > h1 {
                font-size: 25px;
                font-weight: 400;
            }

            > p {
                margin: 20px 0;
            }

            > .link {
                margin-top: 20px;
            }
        }
    }
}