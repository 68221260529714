.information {
    display: flex;
    flex-flow: row wrap-reverse;
    margin-bottom: 100px;

    > section {
        flex: 1 1 400px;
        > img {
            width: 100%;
        }
    }

    > .content {
        padding: 50px;

        > h1 {
            font-size: 27px;
            font-weight: 400;
        }

        > form {
            max-width: 400px;
            margin-top: 40px;

            > * {
                margin-bottom: 20px;
            }

            > button {
                margin-top: 50px;
                width: 200px;
            }
        }
    }
}