.intro {
    background: #999 url(./assets//bg.png) center center/cover no-repeat;
    padding: 230px 30px 120px;
    display: flex;
    flex-flow: column;
    align-items: center;

    > article {
        max-width: 600px;
        text-align: center;
        color: white;

        > h1 {
            font-size: 25px;
            font-weight: 400;
        }

        > h2 {
            margin-top: 20px;
            font-size: 20px;
            font-weight: 600;
        }

        > .links {
            margin-top: 40px;

            > * {
                margin: 10px 20px;
            }
        }
    }
}


@media (max-width: 500px) {
    .intro {
        padding-top: 120px;
        padding-bottom: 70px;
    }
}