.button {
    display: inline-block;
    height: 60px;
    min-width: 100px;
    padding: 10px 40px;
    background-color: black;
    color: white;
    text-decoration: none;
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    border: 0;
    position: relative;
    overflow: hidden;

    &:focus {
        outline: 2px solid orange;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(100%, 100%, 100%, .2);
        // transform: translateX(-20%);
        transform: scaleX(.8);
        transition: all .2s;
        opacity: 0;
    }

    &:hover::before {
        // transform: translateX(0);
        transform: scaleX(1);
        opacity: 1;
    }
}