@keyframes show {
    0% {
        opacity: 0.8;
        backdrop-filter: blur(0);
    }
    100% {
        opacity: 1;
        backdrop-filter: blur(10px);
    }
}

.popup {
    &__show,
    &__hide {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-flow: column;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 100px 30px 50px;
        overflow: auto;
        z-index: 100;

        > section {
            > .popup_container {
                max-width: 500px;
                width: 100%;
                background-color: white;
                border-radius: 10px;
                overflow: hidden;

                > header {
                    height: 50px;
                    padding: 10px;
                    background-color: black;
                    display: flex;
                    flex-flow: column;
                    align-items: center;

                    > img {
                        height: 100%;
                    }
                }

                > img {
                    height: 150px;
                    width: 100%;
                    object-fit: cover;
                    object-position: top center;
                }

                > h1 {
                    font-size: 25px;
                    font-weight: 400;
                    padding: 20px 40px;
                    text-align: center;
                }

                > p {
                    text-align: center;
                    padding: 0 40px 20px;

                    > small {
                        font-size: 12px;
                    }
                }

                > .socials {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: center;
                    margin-bottom: 20px;

                    > * {
                        margin: 10px;
                    }
                }
            }
        }
    }

    &__show {
        animation: show 1s forwards;
    }
    &__hide {
        display: none;
    }
}


@media (max-width: 500px) {
    .popup {
        &__hide, &__show {
            padding-top: 50px;

            > section > .popup_container {
                > img {
                    height: 120px;
                }

                > h1 {
                    padding-top: 15px;
                    padding-bottom: 15px;
                }

                > p {
                    padding-bottom: 15px;
                }
            }
        }
    }
}