.header {
    // height: 70px;
    padding: 30px 30px 10px;
    background-color: black;
    display: flex;
    flex-flow: column;
    align-items: center;

    > a {
        display: inline-block;
        margin: 0 auto;
        height: 50px;

        > img {
            height: 100%;
        }
    }
}