.join_waitlist {
    margin: 20px 0 50px;
    > .header {
        width: 100%;
        max-height: 650px;
        object-fit: cover;
    }

    > .body {
        display: flex;
        flex-flow: row wrap;
        margin-top: -170px;

        > section {
            flex: 1 1 400px;
            padding: 0 50px;
            display: flex;
            flex-flow: column;
            align-items: flex-end;

            > img {
                max-width: 360px;
            }
        }

        > article {
            flex: 1 1 400px;
            max-width: 600px;
            background-color: white;
            margin-top: 80px;
            padding: 50px;

            > p {
                margin-bottom: 50px;
            }

            > ul {
                list-style: disc;
                font-size: 16px;
                margin-bottom: 50px;
                margin-left: 20px;
                
                > li {
                    margin: 5px 0;
                }
            }
        }
    }
}



@media (max-width: 860px) {
    .join_waitlist > .body {
        > section {
            display: none;
        }
        
        > article {
            margin-left: auto;
            margin-right: auto;
        }
        
        
    }
}