.field {
    > input {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #908484;
        font-size: 14px;
        padding: 5px 0;
        outline: 0;
        margin-bottom: 8px;

        &:focus {
            border-bottom-color: black;
        }
    }

    > label {
        font-size: 14px;
        color: #908484;
    }
}